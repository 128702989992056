import { useForm } from 'react-hook-form'

import {
  Navigation,
  If,
  Input,
  Select,
  Shimmer
} from '@itau-loans-www/shopping/src/components'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from '@itau-loans-www/shopping/src/layouts/Proposal'
import { cleanAccents } from 'utils'

import '../formalization.scss'
import { data } from './data'
import usePersonalData from './hooks'

const PersonalData = ({ pageContext }) => {
  const proposalContext = useProposal()
  const { product } = proposalContext
  const hasWppBtn = product?.proposal?.productCode !== 'FGTS'
  const {
    maritalOptions,
    occupationsOptions,
    statesOptions,
    onSubmit,
    isFGTS,
    hasBirthInput,
    submitLoading,
    getLoading
  } = usePersonalData()

  const {
    leadData: { personal_data }
  } = useLead()

  const { genderOptions } = data

  const initialValues = {
    date_of_birth: personal_data?.date_of_birth ?? '',
    marital_status: personal_data?.marital_status ?? '',
    marital_status_id: personal_data?.marital_status_id ?? '',
    occupation: personal_data?.occupation ?? '',
    occupation_id: personal_data?.occupation_id ?? '',
    genderName: personal_data?.genderName ?? '',
    gender: personal_data?.gender ?? '',
    address_city_of_birth: personal_data?.address_city_of_birth ?? '',
    address_state_of_birth: personal_data?.address_state_of_birth ?? '',
    address_state_of_birth_id: personal_data?.address_state_of_birth_id ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid },
    ...formProps
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  return (
    <ProposalLayout maxSteps={4} pageContext={pageContext}>
      <div className="formalization">
        <Shimmer
          isVisible={getLoading}
          height="fit-content"
          margin="0 0 20px 0"
        >
          <h1 className="formalization__title">
            Vamos iniciar sua proposta. Preencha os campos com seus dados
            pessoais
          </h1>
        </Shimmer>

        <form
          className="formalization-personaldata__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="formalization__form-fields">
            <If
              condition={hasBirthInput}
              renderIf={
                <div className="formalization__input-wrapper--full">
                  <Shimmer
                    isVisible={getLoading}
                    height="90%"
                    margin="0 0 20px 0"
                  >
                    <Input.Birthdate
                      autoComplete="on"
                      control={control}
                      id="date_of_birth"
                      name="date_of_birth"
                      label="Data de nascimento"
                      validators={{
                        rules: {
                          validate: {
                            lessThan1901: (value) => {
                              return Number(value.split('/')[2]) > 1901
                            }
                          }
                        },
                        messages: {
                          lessThan1901: 'o ano deve ser maior que 1901'
                        }
                      }}
                    />
                  </Shimmer>
                </div>
              }
            />

            <div className="formalization__input-wrapper--medium formalization__input-wrapper--mobile-full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Select
                  label="Estado civil"
                  name="marital_status"
                  formProps={formProps}
                  options={maritalOptions}
                  disabled={getLoading}
                  filterOptions={({ options, search }) =>
                    options.filter((option) =>
                      cleanAccents(option.label)
                        .toLowerCase()
                        .includes(cleanAccents(search).toLowerCase())
                    )
                  }
                  onChange={(selectedOption) => {
                    formProps.setValue(
                      'marital_status_id',
                      selectedOption.value
                    )
                  }}
                  control={control}
                />
              </Shimmer>
            </div>
            <div className="formalization__input-wrapper--medium formalization__input-wrapper--mobile-full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Select
                  label="Gênero"
                  name="genderName"
                  formProps={formProps}
                  options={genderOptions}
                  disabled={getLoading}
                  filterOptions={({ options, search }) =>
                    options.filter((option) =>
                      cleanAccents(option.label)
                        .toLowerCase()
                        .includes(cleanAccents(search).toLowerCase())
                    )
                  }
                  onChange={(selectedOption) => {
                    formProps.setValue('gender', selectedOption.value)
                  }}
                  control={control}
                />
              </Shimmer>
            </div>
            <If
              condition={isFGTS}
              renderIf={
                <div className="formalization__input-wrapper--full">
                  <Shimmer
                    isVisible={getLoading}
                    height="90%"
                    margin="0 0 20px 0"
                  >
                    <Select
                      label="Profissão"
                      name="occupation"
                      formProps={formProps}
                      options={occupationsOptions}
                      disabled={getLoading}
                      filterOptions={({ options, search }) =>
                        options.filter((option) =>
                          cleanAccents(option.label)
                            .toLowerCase()
                            .includes(cleanAccents(search).toLowerCase())
                        )
                      }
                      onChange={(selectedOption) => {
                        formProps.setValue(
                          'occupation_id',
                          selectedOption.value
                        )
                      }}
                      control={control}
                    />
                  </Shimmer>
                </div>
              }
            />
            <div className="formalization__input-wrapper--small formalization__input-wrapper--mobile-full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Select
                  label="Estado"
                  name="address_state_of_birth"
                  control={control}
                  autocomplete="off"
                  options={statesOptions}
                  formProps={formProps}
                  disabled={getLoading}
                  filterOptions={({ options, search }) =>
                    options.filter((option) =>
                      cleanAccents(option.label)
                        .toLowerCase()
                        .includes(cleanAccents(search).toLowerCase())
                    )
                  }
                  onChange={(selectedOption) => {
                    formProps.setValue(
                      'address_state_of_birth_id',
                      selectedOption.value
                    )
                  }}
                />
              </Shimmer>
            </div>
            <div className="formalization__input-wrapper--big formalization__input-wrapper--mobile-full">
              <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
                <Input.Text
                  label="Cidade de nascimento"
                  id="address_city_of_birth"
                  name="address_city_of_birth"
                  control={control}
                  validators={{
                    rules: {
                      validate: {
                        onlyLetters: (value) =>
                          /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(value)
                      }
                    },
                    messages: {
                      onlyLetters: 'cidade inválida'
                    }
                  }}
                  required
                  className="formalization-personaldata__form-city"
                />
              </Shimmer>
            </div>
          </div>
          <Shimmer isVisible={getLoading} height="90%" margin="0 0 20px 0">
            <Navigation
              nextStep
              nextButtonType="submit"
              hasWppBtn={hasWppBtn}
              isNextButtonDisabled={!isValid}
              isNextStepButtonLoading={submitLoading}
              nextButtonText="Continuar proposta"
              nextButtonId={'formalization-personaldata-next-btn'}
            />
          </Shimmer>
        </form>
      </div>
    </ProposalLayout>
  )
}

export default PersonalData
