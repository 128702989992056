import auth from '@itau-loans-www/shopping/src/services/http/auth'
import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getOccupations = async () => {
  const { data } = await auth({}).then((_http) =>
    _http.get(`v2/itau-360-shopping-api/occupations/`)
  )

  return data
}
const getPersonalData = async (requestBody) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'personal-data',
    method: 'get',
    body: requestBody
  })

  return data
}
const putPersonalData = async (requestBody) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'personal-data',
    method: 'put',
    body: requestBody
  })

  return data
}
const getMaritalStatuses = async () => {
  const { data } = await auth({}).then((_http) =>
    _http.get(`v2/itau-360-shopping-api/marital-statuses/`)
  )

  return data
}

export default {
  getOccupations,
  getPersonalData,
  putPersonalData,
  getMaritalStatuses
}
